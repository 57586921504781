'use client'

import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { Bebas_Neue } from 'next/font/google';
import Link from 'next/link'
 
const headFont = Bebas_Neue({
  weight: ['400'],
  subsets: ['latin'],
});

export default function Error() {
    return (
      <div className="h-full flex-grow p-2 md:p-40 flex flex-col items-center gap-4">
        <h2
          className={cn('text-background text-5xl font-bold', headFont.className)}
        >
          Error
        </h2>
        <p className="opacity-80">Sorry, something went wrong.</p>
        <Link href="/">
          <Button>Back to Homepage</Button>
        </Link>
      </div>
    );
  
}